import React, { useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { changePassword } from '../../actions';
import { ProxyErrorConversion } from '../../utils/Conversion';
import { isMobileOnly } from 'react-device-detect';

const ChangePasswordDialog = ({ dispatch, handleClose, eMail, open, changePasswordError, changePasswordSuccess }) => {

    const [password, setPassword] = useState("");

    const handleConfirm = () => {
        dispatch(changePassword(eMail, password));
    }

    const handlePasswordChanged = (event) => {
        setPassword(event.target.value);
    }

    return <Dialog fullScreen={isMobileOnly} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Passwort ändern</DialogTitle>
        <DialogContent>
            <TextField
                margin="dense"
                id="user-email"
                label="Email"
                fullWidth
                disabled
                value={eMail} />
            <TextField
                autoFocus
                margin="dense"
                id="newPassword"
                type="password"
                label="Passwort"
                onChange={handlePasswordChanged}
                fullWidth />
        </DialogContent>
        {
            changePasswordError && <p style={{
                color: "#f44336",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center"
            }}>{ProxyErrorConversion(changePasswordError)}</p>
        }
        {
            changePasswordSuccess && <p style={{
                color: "green",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center"
            }}>Passwort geändert.</p>
        }
        <DialogActions>
            <Button onClick={handleConfirm} color="primary">
                Passwort ändern
            </Button>
            <Button onClick={handleClose} color="primary">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
}

export default ChangePasswordDialog;