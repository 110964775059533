import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import CustomAppBar from '../CustomAppBar';
import SiloFillings from '../SiloFillings';
import PlantAdminUserList from './PlantAdminUserList';
import { loadSiloFillings, plantApplicationInit } from '../../actions';
import SettingsList from '../SettingsList';
import { BarChart, List, Settings, SupervisorAccount, Home } from '@mui/icons-material';
import LogsList from '../LogsList';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';

const PlantAdminScreen = ({ dispatch }) => {
  useEffect(() => {
    dispatch(plantApplicationInit())
    dispatch(loadSiloFillings())
  }, [dispatch]);

  const onRefresh = () => {
    dispatch(plantApplicationInit())
    dispatch(loadSiloFillings())
  }

  const navigationItems = [
    { "key": "home", "title": "Startseite", "icon": <Home /> },
    { "key": "fillingLevels", "title": "Silofüllstände", "icon": <BarChart /> },
    { "key": "users", "title": "Benutzer", "icon": <SupervisorAccount /> },
    { "key": "settings", "title": "Einstellungen", "icon": <Settings /> },
    { "key": "logs", "title": "Logbuch", "icon": <List /> },
  ]

  return <Router>
    <div>
      <CustomAppBar dispatch={dispatch} title={"Administrator"} onRefresh={onRefresh} drawerActive={true} items={navigationItems}>
        <Switch>
          <Route exact path={["/home", "/"]}>
            <div style={{ display: "block", textAlign: "center" }}>
              <div style={{ display: "inline-block", verticalAlign: "top", textAlign: "left", margin: isMobileOnly ? "0 0 10px 0" : "10px 10px 0 10px" }}>
                <PlantAdminUserList registerUserHeader={"Neuen Lieferanten hinzufügen"} />
              </div>
              <div style={{ display: "inline-block", width: isMobileOnly ? "100%" : "", verticalAlign: "top", textAlign: "left", margin: isMobileOnly ? "0 0 10px 0" : "10px 10px 0 10px" }}>
                <SiloFillings loadSiloFillings={() => dispatch(loadSiloFillings())} />
              </div>
              <div style={{ display: "inline-block", verticalAlign: "top", textAlign: "left", margin: isMobileOnly ? "0 0 10px 0" : "10px 10px 0 10px" }}>
                <SettingsList />
              </div>
            </div>
          </Route>
          <Route path="/fillingLevels">
            <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center", margin: isMobileOnly ? "" : "10px 10px 0 10px" }}>
              <SiloFillings loadSiloFillings={() => dispatch(loadSiloFillings())} />
            </div>
          </Route>
          <Route path="/users">
            <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center", margin: isMobileOnly ? "" : "10px 10px 0 10px" }}>
              <PlantAdminUserList registerUserHeader={"Neuen Lieferanten hinzufügen"} />
            </div>
          </Route>
          <Route path="/settings">
            <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center", margin: isMobileOnly ? "" : "10px 10px 0 10px" }}>
              <SettingsList />
            </div>
          </Route>
          <Route path="/logs">
            <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center" }}>
              <LogsList />
            </div>
          </Route>
        </Switch>
      </CustomAppBar>
    </div>
  </Router>
};

export default compose(connect())(PlantAdminScreen);