import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { addUserPermission } from "../actions";
import { TextField, Button, Card, CardHeader, CardContent, CircularProgress, Autocomplete } from "@mui/material";
import { createStructuredSelector } from "reselect";
import { ErrorText } from "./ErrorText";

const AddUserToPlant = ({ dispatch, addedUserPermissionError, plants, users, addedUserPermissionPending }) => {

    const [mail, setMail] = useState("");
    const [selectedPlant, setSelectedPlant] = useState();

    const commit = () => {
        if (mail === "" || selectedPlant === "") {
            return;
        }
        dispatch(addUserPermission(mail, selectedPlant));
        setMail("");
    };

    return <Card style={{ marginTop: 16, marginBottom: 16, maxWidth: 500, textAlign: "center" }} elevation={5}>
        <CardHeader title={"Benutzer zu Werk hinzufügen"} style={{ paddingBottom: 0, textAlign: "left" }} />
        <CardContent style={{ paddingTop: 0 }}>
            
            <Autocomplete
                disablePortal
                id="combo-box-plant"
                options={plants.map(e => e.plantName)}
                onChange={(event, newValue) => {
                    setSelectedPlant(newValue);
                }}
                style={{paddingTop: 16}}
                renderInput={
                    (params) => <TextField
                        {...params}
                        fullWidth
                        label="Betonwerk" />
                } />

            <Autocomplete
                disablePortal
                id="combo-box-user"
                options={users.map(e => e.email)}
                onChange={(event, newValue) => {
                    setMail(newValue);
                }}
                style={{paddingTop: 16}}
                renderInput={
                    (params) => <TextField
                        {...params}
                        fullWidth
                        label="Benutzername" />
                } />
            {
                addedUserPermissionPending ?
                    <CircularProgress style={{ marginTop: 8, marginBottom: 8 }} /> :
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!selectedPlant || !mail}
                        onClick={commit}
                        style={{ marginTop: 10, color: "#000", background: "#f48413" }}>Hinzufügen</Button>
            }
            <ErrorText error={addedUserPermissionError} />
        </CardContent>
    </Card >
};

const mapStateToProps = createStructuredSelector({
    addedUserPermissionError: state => state.plant.addedUserPermissionError,
    addedUserPermissionPending: state => state.plant.addedUserPermissionPending,
    plants: state => state.plantList.plants,
    users: state => state.users.users,
});

export default compose(connect(mapStateToProps))(AddUserToPlant);