import {
  LOGIN,
  LOGOFF,
  APPLICATION_INIT,
  LOAD_PLANTS, LOAD_USERS,
  REGISTER_USER, REGISTER_PLANT, REMOVE_USER,
  ADD_USER_PERMISSION, LOAD_SILO_FILLINGS,
  completed, failed, pending, REMOVE_USER_PERMISSION, SELECT_PLANT,
  ADD_OR_UPDATE_USER_PERMISSION, PLANT_APPLICATION_INIT,
  REGISTER_SUPPLIER,
  UPDATE_PLANT_EXPIRATION,
  LOAD_PLANT_INFORMATION,
  LOAD_PLANT_EXPIRATION,
  CHANGE_PASSWORD,
  COMMIT_HASH,
  LOAD_SILO_THRESHOLDS,
  UPDATE_PLANT_THRESHOLDS,
  REMOVE_USER_FROM_PLANT,
  LOAD_LOG_ENTRIES,
  SELECTED_ENTRIES_END_TIME,
  SELECTED_ENTRIES_START_TIME,
  SELECTED_ENTRIES_SUPPLIER_NAME
} from "./names";
import raw from "../git-commit.txt";

export const dispatchAwait = (action, dispatch) => {
  dispatch(action);
  return Promise.resolve();
}

const post = (url, payload, token = undefined) => {
  const headers = {
    "Content-Type": "application/json",
  };

  // Add optional auth token
  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }

  const settings = {
    method: "POST",
    cache: "no-cache",
    headers: headers,
    body: JSON.stringify(payload)
  };

  return fetch(url, settings)
};



export const applicationInit = () => (dispatch, getState) => {
  dispatch({
    type: APPLICATION_INIT
  });

  const token = getState().application.loginToken;
  loadAll(dispatch, token);
};

export const plantApplicationInit = () => (dispatch, getState) => {
  dispatch({
    type: PLANT_APPLICATION_INIT
  });
  const token = getState().application.loginToken;
  const plant = getState().application.currentPlant;
  loadAllForPlant(dispatch, token, plant);
};

export const logOff = () => {
  return {
    type: LOGOFF,
  }
};

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: pending(LOGIN),
  });

  const payload = {
    email,
    password,
  };

  post("/api/login/user", payload)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          throw Error("INVALID_LOGIN")
        }
        throw Error("LOGIN_FAILED");
      }
      return response.json();
    })
    .then(json => {
      dispatch({
        type: completed(LOGIN),
        payload: json.token,
        role: json.role,
        plant: json.plant,
        permissions: json.permissions,
        currentUser: email,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: failed(LOGIN),
        error: error.message,
      })
    })
};

const loadPlantInformation = (dispatch, token, plant) => {
  loadJson(dispatch, LOAD_PLANT_INFORMATION, "/api/plant/current?plantName=" + plant, token);
};

const loadPlantSiloFillings = (dispatch, token, plant) => {
  loadJson(dispatch, LOAD_SILO_FILLINGS, "/api/fillingLevels?plant=" + plant, token);
}

export const loadExpirationTime = (plant) => (dispatch, getState) => {
  const token = getState().application.loginToken;
  loadJson(dispatch, LOAD_PLANT_EXPIRATION, "/api/plant/expiration?plantName=" + plant, token);
}

export const loadSiloThresholds = (plant) => (dispatch, getState) => {
  const token = getState().application.loginToken;
  loadJson(dispatch, LOAD_SILO_THRESHOLDS, "/api/plant/thresholds?plantName=" + plant, token);
}

export const loadAllUsers = () => (dispatch, getState) => {
  const token = getState().application.loginToken;
  loadJson(dispatch, LOAD_USERS, "/api/user/list", token);
};

export const loadSiloFillings = () => (dispatch, getState) => {
  const token = getState().application.loginToken;
  const plant = getState().application.currentPlant;
  loadJson(dispatch, LOAD_SILO_FILLINGS, "/api/fillingLevels?plant=" + plant, token);
}

export const loadSpecificSiloFillings = () => (dispatch, getState) => {
  const token = getState().application.loginToken;
  const plant = getState().application.currentPlant;
  const silos = getState().application.permissions.filter(p => p.plantName === plant).flatMap(p => p.silos);

  const query = silos.map(p => "silos=" + encodeURIComponent(p)).join("&");
  loadJson(dispatch, LOAD_SILO_FILLINGS, "/api/fillingLevels/specific?" + query, token);
}

export const loadLogEntries = () => (dispatch, getState) => {
  const token = getState().application.loginToken;
  loadJson(dispatch, LOAD_LOG_ENTRIES, "/api/logs/entries", token);
}

export const loadFilteredLogEntries = (supplierName, start, end, startIndex, amount = 20) => (dispatch, getState) => {
  const token = getState().application.loginToken;
  let query = "?supplierName=" + supplierName + "&startTime=" + start + "&endTime=" + end +
    "&startIndex=" + startIndex + "&amount=" + amount;
  loadJson(dispatch, LOAD_LOG_ENTRIES, "/api/logs/entries" + query, token);
}

const loadUsers = (dispatch, token) => {
  loadJson(dispatch, LOAD_USERS, "/api/user/list", token);
};

const loadPlants = (dispatch, token) => {
  loadJson(dispatch, LOAD_PLANTS, "/api/plant/list", token);
};

const loadAll = (dispatch, token) => {
  loadUsers(dispatch, token);
  loadPlants(dispatch, token);
};

const loadAllForPlant = (dispatch, token, plant) => {
  loadPlantInformation(dispatch, token, plant);
  loadPlantSiloFillings(dispatch, token, plant);
}

const loadJson = (dispatch, actionName, url, token, payload = undefined) => {
  dispatch({
    type: pending(actionName)
  });
  const settings = {
    headers: {
      "Authorization": "Bearer " + token
    },
  };

  if (payload) {
    settings["body"] = JSON.stringify(payload)
  }

  fetchWithRetry(url, 1, 5, settings)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOff());
          throw Error("NOT_AUTHORIZED");
        }
      }
      switch (response.status) {
        case 403: // Forbidden
          throw new Error("PLANT_SERVER_FORBIDDEN");
        case 404: // Not found
        case 503: // Service unavailable
        case 504: // Gateway timeout
          throw new Error("PLANT_SERVER_UNREACHABLE");
        case 500:
          throw new Error("PROXY_SERVER_UNREACHABLE");
        default:
          break;
      }
      if (!response.ok) {
        throw new Error("SERVER_INCOMPATIBLE");
      }
      return response.json()
    })
    .then(json => {
      dispatch({
        type: completed(actionName),
        payload: json,
      })
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: failed(actionName),
        error: error.message
      })
    })
};

const wait = (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

const fetchWithRetry = (url, delay, tries, fetchOptions = {}) => {
  const onError = (err) => {
    var triesLeft = tries - 1;
    if (!triesLeft) {
      throw err;
    }
    return wait(delay).then(() => fetchWithRetry(url, delay, triesLeft, fetchOptions));
  }

  return fetch(url, fetchOptions).catch(onError);
}

const loadHash = (dispatch, actionName) => {
  dispatch({
    type: pending(actionName),
    commitHash: "empty",
  });

  fetch(raw)
    .then(r => r.text())
    .then(commitHash => {
      dispatch({
        type: completed(actionName),
        commitHash,
      })
    }).catch(error => {
      console.error(error);
    });
}

const pushChange = (endpoint, baseActionName, payload) => (dispatch, getState) => {
  dispatch({
    type: pending(baseActionName),
    payload,
  });

  const token = getState().application.loginToken;
  const plant = getState().application.currentPlant;

  post(endpoint, payload, token)
    .then(async response => {
      if (!response.ok) {
        throw Error(await response.text());
      }
      dispatch({
        type: completed(baseActionName),
        payload,
      });

      // Load everythin again
      if (!plant) {
        loadAll(dispatch, token);
      } else {
        loadAllForPlant(dispatch, token, plant);
      }
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: failed(baseActionName),
        error: error.message,
      })
    })
};


export const selectPlant = (name) => {
  return {
    type: SELECT_PLANT,
    payload: name,
  }
};

export const selectEntriesEndTime = (endTime) => {
  return {
    type: SELECTED_ENTRIES_END_TIME,
    payload: endTime,
  }
}

export const selectEntriesStartTime = (startTime) => {
  return {
    type: SELECTED_ENTRIES_START_TIME,
    payload: startTime,
  }
}

export const selectEntriesSupplierName = (supplierName) => {
  return {
    type: SELECTED_ENTRIES_SUPPLIER_NAME,
    payload: supplierName,
  }
}

export const registerPlant = (newPlant, plantPassword) => {
  const payload = {
    plantPassword: plantPassword,
    plant: newPlant,
  };

  return pushChange("/api/plant/register", REGISTER_PLANT, payload);
};

export const registerUser = (eMail, password, userRole) => {
  const payload = {
    eMail,
    password,
    userRole
  };

  return pushChange("/api/user/register", REGISTER_USER, payload);
};

export const registerSupplier = (eMail, password) => {
  const payload = {
    eMail,
    password
  };

  return pushChange("/api/user/registerSupplier", REGISTER_SUPPLIER, payload);
};

export const removeUser = (user) => {
  const payload = {
    eMail: user,
  };

  return pushChange("/api/user/remove", REMOVE_USER, payload);
};

export const addUserPermission = (eMail, plant) => {
  const payload = {
    eMail,
    plant,
  };

  return pushChange("/api/plant/addPermission", ADD_USER_PERMISSION, payload);
};

export const removeUserPermission = (eMail, plant) => {
  const payload = {
    eMail,
    plant,
  };

  return pushChange("/api/plant/removePermission", REMOVE_USER_PERMISSION, payload);
};

export const addOrUpdateUserPermission = (eMail, plant, silos) => {
  const payload = {
    eMail,
    plant,
    silos
  };
  return pushChange("/api/plant/addOrUpdatePermission", ADD_OR_UPDATE_USER_PERMISSION, payload);

}

export const changeExpirationTime = (plant, expirationTime) => {
  const payload = {
    expirationTime,
    plant,
  };
  return pushChange("/api/plant/updateExpiration", UPDATE_PLANT_EXPIRATION, payload);

}

export const changeSiloThresholds = (plant, thresholds) => {
  const payload = {
    thresholds,
    plant,
  };
  return pushChange("/api/plant/updateThresholds", UPDATE_PLANT_THRESHOLDS, payload);
}

export const removeUserFromPlant = (plant, eMail) => {
  const payload = {
    eMail,
    plant,
  };
  return pushChange("/api/plant/removeUserFromPlant", REMOVE_USER_FROM_PLANT, payload);
}

export const changePassword = (eMail, newPassword) => {
  const payload = {
    eMail,
    newPassword,
  };
  return pushChange("/api/user/changePassword", CHANGE_PASSWORD, payload);

}

export const loadCommitHash = (dispatch) => {
  loadHash(dispatch, COMMIT_HASH);
}

export const passwordChanged = () => {
  return {
    type: CHANGE_PASSWORD,
  }
}