import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { registerPlant } from "../actions";
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    TextField,
} from '@mui/material';
import { createStructuredSelector } from "reselect";
import { ErrorText } from "./ErrorText";
import { isMobileOnly } from "react-device-detect";


const DEFAULT_PLANT_PASSWORD = "";

const RegisterPlant = ({ dispatch, addedPlantError }) => {
    const [newPlant, setNewPlant] = useState("");
    const [plantPassword, setPlantPassword] = useState(DEFAULT_PLANT_PASSWORD);

    const register = () => {
        dispatch(registerPlant(newPlant, plantPassword));
        setNewPlant("");
        setPlantPassword(DEFAULT_PLANT_PASSWORD);
    };

    return <Card style={{ width: isMobileOnly ? "100%" : "500px", background: "#ddd" }} elevation={5}>
        <CardHeader title="Neues Betonwerk registrieren" style={{ paddingBottom: 0 }} />
        <CardContent>
            <TextField
                label="Betonwerk"
                type={"text"}
                fullWidth
                value={newPlant}
                placeholder={"A??.???"}
                onChange={event => setNewPlant(event.target.value)} />

            <TextField
                label="Passwort"
                placeholder={"Leave empty to auto-generate"}
                type={"text"}
                fullWidth
                style={{ marginTop: 16 }}
                value={plantPassword}
                onChange={event => setPlantPassword(event.target.value)} />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!newPlant}
                onClick={register}
                style={{ marginTop: 10, background: "#f48413", color: "#000" }}
            >Registrieren</Button>
            <ErrorText  error={addedPlantError}/>
        </CardContent>
    </Card>
}
const mapStateToProps = createStructuredSelector({
    addedPlantError: state => state.plant.addedPlantError,
});

export default compose(connect(mapStateToProps))(RegisterPlant);