export const RoleConversion = (role) => {
    if (role === "SystemAdmin") {
        return "Administrator";
    } else if (role === "Supplier") {
        return "Lieferant";
    } else if (role === "PlantAdmin") {
        return "Werkadministrator";
    }
}

export const LogErrorConversion = (error) => {
    switch (error) {
        case "EXPIRED":
            return "QR-Code abgelaufen";
        case "SILO_FULL":
            return "Silo voll";
        case "WRONG_CONTENT":
            return "Inhalt stimmt nicht überein";
        case "SILO_MISMATCH":
            return "Siloauswahl nicht eindeutig";
        default:
        case "NONE":
            return "Kein Fehler";
    }
}

export const ProxyErrorConversion = (error) => {
    if(error.includes("ECONNREFUSED")){
        return "Es ist ein Verbindungsfehler mit dem Proxyserver aufgetreten"
    }
    switch (error) {
        case "PROXY_SERVER_UNREACHABLE":
            return "Es ist ein Verbindungsfehler mit dem Proxyserver aufgetreten";
        case "NOT_AUTHORIZED":
            return "Nicht authorisiert";
        case "PLANT_SERVER_FORBIDDEN":
            return "Verbindung zu Betonwerk fehlgeschlagen";
        case "PLANT_SERVER_UNREACHABLE":
            return "Betonwerk nicht erreichbar";
        case "INVALID_LOGIN":
            return "Zugangsdaten ungültig.";
        case "LOGIN_FAILED":
            return "Login fehlgeschlagen";
        case "SERVER_INCOMPATIBLE":
            return "Serverversion nicht kompatibel";
        case "INVALID_USERNAME":
            return "Ungültiger Benutzername";
        case "USERNAME_ALREADY_TAKEN":
            return "Benutzername bereits vorhanden";
        case "PASSWORD_TOO_SIMPLE":
            return "Passwort zu leicht (min. 8 Zeichen)";
        case "INVALID_PLANT":
            return "Ungültiges Betonwerk";
        case "INVALID_USER":
            return "Ungültiger Benutzer";
        case "PLANTNAME_EXISTS_ALREADY":
            return "Betonwerkname existiert bereits";
        case "PLANTNAME_CANNOT_BE_EMPTY":
            return "Betonwerkname darf nicht leer sein";
        case "USER_NOT_FOUND":
            return "Benutzer nicht gefunden";
        case "SYSTEM_ADMIN_NOT_ADDING_TO_PLANT":
            return "Systemadministratoren können keinem Werk zugewiesen werden";
        case "CURRENTLY_NO_PLANT_ASSIGNED":
            return "Bisher wurde kein Werk zugewiesen";
        case "CANNOT_ADD_EXISTING_USER_TO_PLANT":
            return "Bestehender Benutzer kann dem Werk nicht hinzugefügt werden";
        default:
            return error;
    }
}