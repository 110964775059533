import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {Card, CardContent, CardHeader, Button, TextField} from '@mui/material';
import { registerSupplier } from "../../actions";
import { createStructuredSelector } from "reselect";
import { ErrorText } from "../ErrorText";

const DEFAULT_PASSWORD = "Durlach76227";

const RegisterSupplier = ({ dispatch, registerSupplierPending, registerSupplierError }) => {
    const [newMail, setNewMail] = useState("");
    const [initialPassword, setInitialPassword] = useState(DEFAULT_PASSWORD);

    const register = () => {
        dispatch(registerSupplier(newMail, initialPassword));
        setNewMail("");
        setInitialPassword(DEFAULT_PASSWORD);
    };

    return (
        <Card style={{ maxWidth: 500, textAlign:"center" }} elevation={5}>
            <CardHeader title={"Neuen Lieferanten hinzufügen"} style={{ paddingBottom: 0, textAlign:"left" }} />
            <CardContent>
                <TextField
                    label="Benutzername"
                    type={"text"}
                    fullWidth
                    disabled={registerSupplierPending}
                    value={newMail}
                    onChange={event => setNewMail(event.target.value)} />

                <TextField
                    label="Passwort"
                    type={"text"}
                    fullWidth
                    disabled={registerSupplierPending}
                    style={{ marginTop: 16 }}
                    value={initialPassword}
                    onChange={event => setInitialPassword(event.target.value)} />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={registerSupplierPending || !newMail}
                    onClick={register}
                    style={{ marginTop: 10, background: "#f48413", color: "#000" }}
                >Registrieren
                </Button>
                <ErrorText error={registerSupplierError}/>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    registerSupplierError: state => state.plant.registerSupplierError,
    registerSupplierPending: state => state.plant.registerSupplierPending,
});

export default compose(connect(mapStateToProps))(RegisterSupplier);
