import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';


export const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 1)", // (default alpha is 0.38)
            borderColor: "#000",
            WebkitTextFillColor:"#000"
        }
    }
})(TextField);