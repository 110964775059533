import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, InputAdornment, ListItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import fecha from "fecha";
import DownloadDialog from './DownloadDialog';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from '../../utils/useForm';
import { isNumber } from '../../utils';
import { isMobileOnly } from 'react-device-detect';

const QRCodeDialog = ({ silo, open, handleClose, expirationTime, currentUser, userRole }) => {

    var expiration = new Date();
    expiration.setHours(expiration.getHours() + expirationTime);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const [driver, setDriver] = useState("");
    const [amount, setAmount] = useState(0);
    const [numberPlate, setNumberPlate] = useState("");

    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = { ...errors }
        temp.driver = driver.length > 0 ? "" : "Dises Feld ist notwendig";
        temp.numberPlate = numberPlate.length > 6 ? "" : "Kennzeichen nicht gültig.";
        if (isNumber(amount)) {
            temp.amount = parseInt(amount) > 0 ? "" : "Ungültige Liefermenge.";
        } else {
            temp.amount = "Liefermenge muss eine Zahl sein.";
        }

        setErrors({ ...temp })
        return Object.values(temp).every(x => x === "")
    }

    const handleAmountChange = (event) => {
        let value;
        if (!isNumber(event.target.value))
            value = 0;
        else
            value = parseInt(event.target.value);
        if (value > 100 || value < 0)
            return;
        setAmount(value);
        validate();
    }

    const handleValueChanged = (e, setValue) => {
        setValue(e.target.value);
        validate();
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (validate()) {
            setDialogOpen(!dialogOpen);
        }
    }

    const handleDialogClose = () => {
        handleClose();
    }

    return <Dialog fullScreen={isMobileOnly} open={open} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">QR-Code erstellen für Silo "{silo.content}"</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {'Bitte ergänzen Sie die unten stehenden Daten, um einen QR-Code für das gewählte Silo zu erstellen'}
            </DialogContentText>
            <Form onSubmit={handleSubmit} id="my-form-id">
                <TextField
                    margin="dense"
                    id="supplier"
                    label="Lieferant"
                    fullWidth
                    disabled
                    value={currentUser}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="lieferantenName"
                    label="Fahrer"
                    name="driver"
                    onChange={(e) => handleValueChanged(e, setDriver)}
                    value={driver}
                    fullWidth
                    {
                    ...(errors.driver && { error: true, helperText: errors.driver })
                    }
                />
                <TextField
                    margin="dense"
                    id="kennzeichen"
                    name="numberPlate"
                    label="Kennzeichen"
                    placeholder="AB-CD-1234"
                    onChange={(e) => handleValueChanged(e, setNumberPlate)}
                    value={numberPlate}
                    fullWidth
                    {
                    ...(errors.numberPlate && { error: true, helperText: errors.numberPlate })
                    }
                />
                <TextField
                    margin="dense"
                    id="amount"
                    name="amount"
                    label="Menge"
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Tonnen</InputAdornment>,
                    }}
                    onChange={handleAmountChange}
                    value={amount}
                    type="number"
                    {
                    ...(errors.amount && { error: true, helperText: errors.amount })
                    }
                />
                <TextField
                    margin="dense"
                    id="silo-number"
                    label="Inhalt"
                    fullWidth
                    disabled
                    value={silo.content}
                />
                <TextField
                    margin="dense"
                    id="expiration"
                    label="Ablaufdatum"
                    fullWidth
                    disabled
                    value={fecha.format(expiration, "DD/MM/YYYY HH:mm")}
                />
                {
                    userRole === "PlantAdmin" && <ListItem role={undefined} button style={{ paddingLeft: 0 }} onClick={() => setChecked(!checked)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={checked} />
                        </ListItemIcon>
                        <ListItemText primary={"Erstellt durch Betonwerk"} />
                    </ListItem>
                }
                <DownloadDialog open={dialogOpen} content={silo.content} expirationTime={expirationTime} driver={driver}
                    amount={amount} handleClose={() => setDialogOpen(false)} numberPlate={numberPlate} supplier={currentUser} createdByPlant={checked} />
            </Form>
        </DialogContent>
        <DialogActions>
            <Button type="submit" form="my-form-id" onSubmit={handleSubmit} color="primary">
                Erstellen
            </Button>
            <Button onClick={handleDialogClose} color="primary">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
}
const mapStateToProps = createStructuredSelector({
    expirationTime: state => state.plant.expirationTime,
    currentUser: state => state.application.currentUser,
    userRole: state => state.application.userRole,
});

export default compose(connect(mapStateToProps))(QRCodeDialog);