import React from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import UserEntry from "./UserEntry";
import RegisterUser from "./RegisterUser";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  CircularProgress,
} from '@mui/material'
import AddUserToPlant from "./AddUserToPlant";
import { isMobileOnly } from "react-device-detect";


const UserList = ({ users, userRole, pending, registerUserHeader, onChangePassword, onRemove, currentUser, error }) => {
  return <div style={{ width: isMobileOnly ? "100%": "500px" }}>
    <Card elevation={5}>
      <CardHeader title="Benutzer" style={{ paddingBottom: 0 }} />
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <List>
          {
            users.map((user, index) => <UserEntry key={index} eMail={user.email} onChangePassword={onChangePassword}
              userRole={user.userRole} onRemove={currentUser === user.email ? undefined : onRemove} last={index === users.length - 1} />)
          }
        </List>
        {
          pending && <CircularProgress />
        }
      </CardContent>
    </Card>
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <RegisterUser currentUserRole={userRole} pending={pending} header={registerUserHeader} error={error} />
      <AddUserToPlant />
    </div>
  </div>
};

const mapStateToProps = createStructuredSelector({
  users: state => state.users.users,
  pending: state => state.users.pending,
  userRole: state => state.application.userRole,
  currentUser: state => state.application.currentUser,
  error: state => state.users.error,
});

export default compose(connect(mapStateToProps))(UserList);