import React, { useEffect } from 'react';
import { loadExpirationTime, loadSiloThresholds, loadSpecificSiloFillings } from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import SiloFillings from '../SiloFillings';
import CustomAppBar from '../CustomAppBar';
import { createStructuredSelector } from 'reselect';

const SupplierScreen = ({ dispatch, permissions, plant }) => {
  useEffect(() => {
    dispatch(loadExpirationTime(plant));
    dispatch(loadSiloThresholds(plant));
    dispatch(loadSpecificSiloFillings(plant));
  }, [dispatch, plant, permissions]);

  const onRefresh = () => {
    dispatch(loadExpirationTime(plant));
    dispatch(loadSiloThresholds(plant));
    dispatch(loadSpecificSiloFillings(plant));
  }

  return (
    <div>
      <CustomAppBar dispatch={dispatch} title={"Lieferant"} onRefresh={onRefresh} drawerActive={false} />
      <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center" }}>
        <SiloFillings loadSiloFillings={() => dispatch(loadSpecificSiloFillings(plant))} 
          loadThresholds={() =>dispatch(loadSiloThresholds(plant))} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissions: state => state.application.permissions,
  plant: state => state.application.currentPlant,
});


export default compose(connect(mapStateToProps))(SupplierScreen);