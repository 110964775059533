
import React, { useEffect, useState } from "react";
import { loadCommitHash, login } from "../actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Divider, TextField, CardContent, Button, CircularProgress, Card, IconButton, InputAdornment, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isMobileOnly } from "react-device-detect";
import { ErrorText } from "./ErrorText";

const LoginScreen = ({ dispatch, loginError, commitHash, loginPending }) => {

  useEffect(() => {
    loadCommitHash(dispatch);
  }, [dispatch]);

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  return <Box component="main" sx={{ flexGrow: 1, display: "flex", justifyContent: "center", p: isMobileOnly ? 1 : 2 }}>
    <Card component="main" elevation={5} style={{
      maxWidth: 400, padding: '0 24px 0 24px', display: "flex", alignSelf: "center", alignItems: "center"
      , position: 'absolute', top: '50%', msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'
    }}>
      <CardContent>
        <h1>Automatic Silo Supply</h1>
        <form id="login-form" onSubmit={() => dispatch(login(mail.trim(), password))} style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <TextField
            variant="outlined" margin="normal"
            fullWidth
            label="Benutzername"
            onChange={(event) => setMail(event.target.value)}
          />
          <TextField
            variant="outlined" margin="normal"
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {loginPending
            ? <CircularProgress style={{ marginTop: 8, marginBottom: 8 }} />
            : <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => dispatch(login(mail.trim(), password))}
              style={{ marginTop: 16, marginBottom: 16, background: "#f48413", color: "#000" }}>Einloggen</Button>
          }
          <ErrorText error={loginError}/>
          <Divider style={{ width: "100%", marginTop:16 }} />
          {
            commitHash && <p style={{
              color: "#ccc",
              fontWeight: "bold",
              marginBottom: 0,
              display: "flex",
              justifyContent: "center"
            }}>Version: {commitHash}</p>
          }
        </form>
      </CardContent>
    </Card>
  </Box >
};

const mapStateToProps = createStructuredSelector({
  loginError: state => state.application.loginError,
  loginPending: state => state.application.loginPending,
  commitHash: state => state.application.commitHash,
});

export default compose(connect(mapStateToProps))(LoginScreen);