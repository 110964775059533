import { completed, failed, LOAD_PLANTS, pending } from "../actions/names";

const DEFAULT_STATE = {
  plants: [],
  error: null,
  pending: null,
};

const plantList = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOAD_PLANTS): {
      return {
        ...state,
        plants: action.payload,
        pending: false,
      }
    }
    case failed(LOAD_PLANTS): {
      return {
        ...state,
        error: action.error,
        pending: false,
      }
    }
    case pending(LOAD_PLANTS): {
      return {
        ...state,
        pending: true,
      }
    }
    default: {
      return state;
    }
  }
}
export default plantList;
