export const APPLICATION_INIT = "APPLICATION_INIT";
export const PLANT_APPLICATION_INIT = "PLANT_APPLICATION_INIT";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_PLANT_USERS = "LOAD_PLANT_USERS";
export const LOAD_PLANT_INFORMATION = "LOAD_PLANT_INFORMATION";
export const LOAD_PLANT_EXPIRATION = "LOAD_PLANT_EXPIRATION";
export const LOAD_SILO_THRESHOLDS = "LOAD_SILO_THRESHOLDS";
export const LOAD_PLANTS = "LOAD_PLANTS";
export const LOAD_SILO_FILLINGS = "LOAD_SILO_FILLINGS";
export const LOAD_SUPPLIER_SILO_FILLINGS = "LOAD_SUPPLIER_SILO_FILLINGS";

export const SELECT_PLANT = "SELECT_PLANT";

export const LOAD_LOG_ENTRIES = "LOAD_LOG_ENTRIES";
export const SELECTED_ENTRIES_START_TIME = "SELECTED_ENTRIES_START_TIME";
export const SELECTED_ENTRIES_END_TIME = "SELECTED_ENTRIES_END_TIME";
export const SELECTED_ENTRIES_SUPPLIER_NAME = "SELECTED_ENTRIES_SUPPLIER_NAME";

export const COMMIT_HASH = "COMMIT_HASH";

export const LOGIN = "LOGIN";
export const LOGOFF = "LOGOFF";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const REGISTER_PLANT = "REGISTER_PLANT";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUPPLIER = "REGISTER_SUPPLIER";
export const REMOVE_USER = "REMOVE_USER";
export const ADD_USER_PERMISSION = "ADD_USER_PERMISSION";
export const ADD_OR_UPDATE_USER_PERMISSION = "ADD_OR_UPDATE_USER_PERMISSION";
export const REMOVE_USER_PERMISSION = "REMOVE_USER_PERMISSION";
export const UPDATE_PLANT_EXPIRATION = "UPDATE_PLANT_EXPIRATION";
export const UPDATE_PLANT_THRESHOLDS = "UPDATE_PLANT_THRESHOLDS";
export const REMOVE_USER_FROM_PLANT = "REMOVE_USER_FROM_PLANT";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const pending = (name) => {
    return name + "_PENDING";
  };
  
  export const failed = (name) => {
    return name + "_FAILED";
  };
  
  export const completed = (name) => {
    return name + "_COMPLETED";
  };