import React from "react";
import { Provider } from "react-redux";
import App from "./App";
import LoginGate from "./LoginGate";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isMobileOnly } from "react-device-detect";

const theme = createTheme({
  palette: {
    primary: {
      main: '#f48413',
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    useNextVariants: true
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14
        }
      }
    },
    ...(isMobileOnly && {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: "100%"
          }
        }
      }
    }),
  }
});

const Root = ({ store }) => {
  return <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LoginGate>
        <App />
      </LoginGate>
    </ThemeProvider >
  </Provider>;
};

export default Root;
