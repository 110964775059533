import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
    Download as DownloadIcon,
    Warning as WarningIcon,
    Check as CheckIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {
    CardContent, CardHeader, Card,
    TextField, Button, TablePagination, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TableFooter
} from "@mui/material";
import { loadFilteredLogEntries } from "../actions";
import { format } from "fecha";
import { LoadingDialog } from "./dialogs/LoadingDialog";
import ReactInputMask from "react-input-mask";
import { isMobileOnly } from "react-device-detect";
import LogEntryDialog from "./dialogs/LogEntryDialog";
import { LogErrorConversion, ProxyErrorConversion } from "../utils/Conversion";

const LogsList = ({ dispatch, entries, errorEntries, loginToken, totalRequestEntries, loadingEntries }) => {

    useEffect(() => {
        let now = new Date();
        let past = new Date();
        now.setDate(now.getDate() - 14);
        dispatch(loadFilteredLogEntries("", format(new Date(now), "MM/DD/YYYY"), format(new Date(past), "MM/DD/YYYY"), 0, amount));
    }, [dispatch]);

    const amount = 20;

    const now = new Date();
    const past = new Date();
    now.setDate(now.getDate() - 14);

    const [startTime, setStartTime] = useState(format(new Date(now), "MM/DD/YYYY"));
    const [endTime, setEndTime] = useState(format(new Date(past), "MM/DD/YYYY"));
    const [supplierName, setSupplierName] = useState("");
    const [tmpsupplierName, setTmpSupplierName] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogError, setDialogError] = useState("");

    const [dialogSummaryOpen, setDialogSummaryOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState();

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const exportToCSV = () => {
        setDialogOpen(true);
        setDialogError("");
        const settings = {
            headers: {
                "Authorization": "Bearer " + loginToken
            },
        };
        let query = "?supplierName=" + supplierName + "&startTime=" + startTime + "&endTime=" + endTime;

        fetch("/api/logs/export" + query, settings)
            .then(response => {
                if (!response.ok) {
                    throw new Error("SERVER_INCOMPATIBLE");
                }
                return response.json();
            })
            .then(json => {
                let headers = ["Datum", "Uhrzeit", "Lieferant", "Inhalt", "Menge", "Nummernschild", "Fahrer", "Werkserstellt", "Fehler"];
                let entries = json.map(e =>
                    [format(new Date(e.scanned), "DD.MM.YYYY"), format(new Date(e.scanned), "HH:mm:ss"), e.supplierName,
                    e.content, e.amount + "t", e.numberPlate, e.driver, e.createdByPlant, e.errorCode],
                ).map(e => e.map(String).map(v => `"${v}"`));

                let csvData = [headers, ...entries].map(e => e.join(";")).join("\n");

                var blob = new Blob([csvData], { type: "data:text/csv;charset=utf-8;" });
                var url = URL.createObjectURL(blob);

                var link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", "Zuschlagslieferung.csv");
                document.body.appendChild(link); // Required for FF
                link.click();
                setDialogOpen(false);
            })
            .catch(error => {
                setDialogError(error.message);
            });
    }

    const search = () => {
        setCurrentPage(0);
        setSupplierName(tmpsupplierName);
        dispatch(loadFilteredLogEntries(tmpsupplierName, startTime, endTime, 0, rowsPerPage));
    }

    const handleChangePage = (event, newPage) => {
        dispatch(loadFilteredLogEntries(supplierName, startTime, endTime, newPage * rowsPerPage, rowsPerPage));
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        setCurrentPage(0);
        dispatch(loadFilteredLogEntries(supplierName, startTime, endTime, 0, rows));
    };

    const columns = [
        { id: "icon", label: "", format: (val) => val === "NONE" ? <CheckIcon style={{ color: "#007E33" }} /> : <WarningIcon style={{ color: "#ff4444" }} />, removeOnMobile: false },
        { id: "scanned_date", label: "Datum", format: (value) => format(new Date(value), isMobileOnly ? "DD.MM.YY HH:mm" : "DD.MM.YY"), removeOnMobile: false },
        { id: "scanned_time", label: "Uhrzeit", format: (value) => format(new Date(value), "HH:mm"), removeOnMobile: true },
        { id: "content", label: "Inhalt", format: (val) => val, removeOnMobile: false },
        { id: "supplier", label: "Lieferant", format: (val) => val, removeOnMobile: false },
        { id: "driver", label: "Fahrer", minWidth: 100, format: (val) => val, removeOnMobile: true },
        { id: "numberPlate", label: "Nummernschild", minWidth: 100, format: (val) => val, removeOnMobile: true },
        { id: "errorCode", label: "Fehler", minWidth: 100, format: (val) => LogErrorConversion(val), removeOnMobile: true },
    ];

    const rows = entries.map((entry) => {
        return {
            content: entry.content, supplier: entry.supplierName, scanned_date: entry.scanned,
            scanned_time: entry.scanned, driver: entry.driver, numberPlate: entry.numberPlate,
            errorCode: entry.errorCode, icon: entry.errorCode, entry: entry
        };
    });

    return <div style={{ minWidth: isMobileOnly ? "100%" : "500px" }}>
        <LoadingDialog error={dialogError} title={"Logbucheinträge werden heruntergeladen..."} open={dialogOpen} handleClose={() => setDialogOpen(false)} />
        <Card elevation={5}>
            <CardHeader title={"Logbucheinträge"} />
            <CardContent>
                <div style={{ verticalAlign: "middle", display: "inline-block" }}>
                    <TextField size="small" id="filled-basic" label="Lieferant" variant="filled" value={tmpsupplierName} onChange={(e) => setTmpSupplierName(e.target.value)} style={{ margin: 5 }} />
                    <ReactInputMask
                        mask="99/99/9999"
                        maskPlaceholder="mm/dd/yyyy"
                        onChange={(e) => setStartTime(e.target.value)}
                        value={startTime}
                        disabled={false}>
                        {() => <TextField size="small" id="filled-basic" variant="filled" label="Startdatum" style={{ margin: 5 }} />}
                    </ReactInputMask>
                    <ReactInputMask
                        mask="99/99/9999"
                        maskPlaceholder="mm/dd/yyyy"
                        onChange={(e) => setEndTime(e.target.value)}
                        value={endTime}
                        disabled={false}>
                        {() => <TextField size="small" id="filled-basic" variant="filled" label="Enddatum" style={{ margin: 5 }} />}
                    </ReactInputMask>
                    <Button type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: 5 }}
                        disabled={loadingEntries}
                        startIcon={<SearchIcon />}
                        onClick={() => search()}>
                        Suche
                    </Button>
                    <Button type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loadingEntries}
                        style={{ margin: 5 }}
                        startIcon={<DownloadIcon />}
                        onClick={() => exportToCSV()}>
                        Exportieren
                    </Button>
                </div>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size={"small"}>
                        <TableHead>
                            <TableRow key={"header"}>
                                {columns.filter(c => !(c.removeOnMobile && isMobileOnly)).map((column) => (
                                    <TableCell
                                        key={column.id}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (!errorEntries && rows) && rows.map((row) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.code}>
                                            {
                                                columns.filter(c => !(c.removeOnMobile && isMobileOnly)).map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell 
                                                            key={column.id} onClick={() => { setSelectedEntry(row.entry); setDialogSummaryOpen(true); }}
                                                            style={{ cursor: "pointer" }}>
                                                            {column.format(value)}
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                            {
                                errorEntries && <TableRow>
                                    <TableCell align="middle"><WarningIcon style={{ color: "#ff4444" }} /></TableCell>
                                    <TableCell align="middle" colSpan={columns.filter(c => !(c.removeOnMobile && isMobileOnly)).length - 1} style={{ color: "red" }}>{ProxyErrorConversion(errorEntries)}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow key={"pagination"}>
                                <TablePagination
                                    rowsPerPageOptions={isMobileOnly ? [] : [10, 25, 50, 100]}
                                    colSpan={columns.filter(c => !(c.removeOnMobile && isMobileOnly)).length}
                                    count={totalRequestEntries}
                                    page={currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={"Einträge pro Seite"}

                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
        <LogEntryDialog open={dialogSummaryOpen} handleClose={() => setDialogSummaryOpen(false)} entry={selectedEntry} />
    </div>
}

const mapStateToProps = createStructuredSelector({
    entries: state => state.logs.entries,
    totalEntries: state => state.logs.totalEntries,
    totalRequestEntries: state => state.logs.totalRequestEntries,
    loadingEntries: state => state.logs.loadingEntries,
    errorEntries: state => state.logs.errorEntries,
    loginToken: state => state.application.loginToken,
});

export default compose(connect(mapStateToProps))(LogsList);