import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { registerUser } from "../actions";
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { ErrorText } from "./ErrorText";
import { isMobileOnly } from "react-device-detect";

const DEFAULT_PASSWORD = "Durlach76227";

const RegisterUser = ({ dispatch, currentUserRole, pending, header, error }) => {
    const [newMail, setNewMail] = useState("");
    const [initialPassword, setInitialPassword] = useState(DEFAULT_PASSWORD);
    const [userRole, setUserRole] = useState("PlantAdmin");

    const register = () => {
        dispatch(registerUser(newMail, initialPassword, userRole, []));
        setNewMail("");
        setInitialPassword(DEFAULT_PASSWORD);
        setUserRole("PlantAdmin");
    };

    const handleChange = (event) => {
        setUserRole(event.target.value);
    };

    return (
        <Card style={{ width: isMobileOnly ? "100%" : "500px" }} elevation={5}>
            <CardHeader title={header} style={{ paddingBottom: 0 }} />
            <CardContent>
                <TextField
                    label="Benutzername"
                    type={"text"}
                    fullWidth
                    value={newMail}
                    placeholder={"A??.???.N"}
                    onChange={event => setNewMail(event.target.value)} />

                <TextField
                    label="Passwort"
                    type={"text"}
                    fullWidth
                    style={{ marginTop: 16 }}
                    value={initialPassword}
                    onChange={event => setInitialPassword(event.target.value)} />

                {
                    currentUserRole === "SystemAdmin" && <RadioGroup value={userRole} onChange={handleChange} style={{ marginTop: 16 }}>
                        <FormControlLabel value="SystemAdmin" control={<Radio />} label="Wiggert Administrator" />
                        <FormControlLabel value="PlantAdmin" control={<Radio />} label="Betonwerk Administrator" />
                    </RadioGroup>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={pending || !newMail}
                    onClick={register}
                    style={{ marginTop: 10, background: "#f48413", color: "#000" }}
                >Registrieren
                </Button>
                <ErrorText error={error} />
            </CardContent>
        </Card>
    );
};

export default compose(connect())(RegisterUser);
