import React from 'react';
import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import { DarkerDisabledTextField } from '../../utils/DarkerDisabledTextField';
import { InfoRounded, Warning } from '@mui/icons-material';
import { LogErrorConversion } from '../../utils/Conversion';
import { format } from 'fecha';
import { isMobileOnly } from 'react-device-detect';

const LogEntryDialog = ({ entry, open, handleClose }) => {
    if (!entry)
        return <div></div>;

    return <Dialog fullScreen={isMobileOnly} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Zusammenfassung</DialogTitle>
        <DialogContentText>

        </DialogContentText>
        <DialogContent>
            <DarkerDisabledTextField
                margin="dense"
                id="log_supplier"
                label="Lieferant"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={entry.supplierName ? entry.supplierName : "Nicht verfügbar"} />
            {
                entry.errorCode !== "NONE" && <DarkerDisabledTextField
                    margin="dense"
                    id="log_errorCode"
                    label="Fehler"
                    fullWidth
                    disabled
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Warning style={{ color: "#ff4444" }} /></InputAdornment>
                    }}
                    style={{ marginRight: 0 }}
                    value={entry.errorCode ? LogErrorConversion(entry.errorCode) : "Nicht verfügbar"} />
            }
            {
                entry.createdByPlant && <DarkerDisabledTextField
                    margin="dense"
                    id="log_createdByPlant"
                    label="Erstellung"
                    fullWidth
                    disabled
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><InfoRounded style={{ color: "#33b5e5" }} /></InputAdornment>
                    }}
                    style={{ marginRight: 0 }}
                    value={"Im Betonwerk erstellt"} />
            }
            <DarkerDisabledTextField
                margin="dense"
                id="log_siloContent"
                label="Inhalt"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={entry.content ? entry.content : "Nicht verfügbar"}
            />
            <DarkerDisabledTextField
                margin="dense"
                id="log_amount"
                label="Menge"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={entry.amount ? entry.amount + "t" : "Nicht verfügbar"}
            />
            <DarkerDisabledTextField
                margin="dense"
                id="log_amount"
                label="Fahrer"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={entry.driver ? entry.driver : "Nicht verfügbar"}
            />
            <DarkerDisabledTextField
                margin="dense"
                id="log_numberPlate"
                label="Nummernschild"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={entry.numberPlate ? entry.numberPlate : "Nicht verfügbar"}
            />
            <DarkerDisabledTextField
                margin="dense"
                id="log_scanned"
                label="Gescannt"
                fullWidth
                disabled
                style={{ marginRight: 0 }}
                value={format(new Date(entry.scanned), "DD.MM.YYYY HH:mm")}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}
export default LogEntryDialog;