import React from "react";
import { RoleConversion } from "../utils/Conversion";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';


const UserEntry = ({ eMail, userRole, onRemove, onChangePassword, last }) => {
  return (<div>
    <ListItem key={eMail} style={{ paddingLeft: 0 }}>
      <ListItemText primary={eMail} secondary={RoleConversion(userRole)} />
      <ListItemSecondaryAction>
        {onRemove && <IconButton edge="end" aria-label="delete" onClick={() => onRemove(eMail)}>
          <DeleteIcon />
        </IconButton>}
        {onChangePassword && <IconButton edge="end" aria-label="delete" onClick={() => onChangePassword(eMail)}>
          <EditIcon />
        </IconButton>}
      </ListItemSecondaryAction>
    </ListItem>
    {
      !last && <Divider />
    }
  </div>
  )
};

export default UserEntry;