import React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { removeUser } from '../../actions';
import { isMobileOnly } from 'react-device-detect';

const DeleteUserDialog = ({ dispatch, handleClose, eMail, open }) => {

    const handleConfirm = () => {
        dispatch(removeUser(eMail));
    }

    return <Dialog fullScreen={isMobileOnly} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Benutzer löschen</DialogTitle>
        <DialogContent>
            <TextField
                margin="dense"
                id="user-email"
                label="Email"
                fullWidth
                disabled
                value={eMail} />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirm} color="primary">
                Bestätigen
            </Button>
            <Button onClick={handleClose} color="primary">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteUserDialog;