import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import {
    InputAdornment, List, ListItem, Card, CardHeader, CardContent, Button, Divider, TextField, IconButton, Tooltip
} from "@mui/material";
import { changeExpirationTime, changeSiloThresholds, loadSiloThresholds } from "../actions";
import { isMobileOnly } from "react-device-detect";
import { Info } from "@mui/icons-material";

const SettingsList = ({ dispatch, expirationTime, plant, siloFillings, siloThresholds }) => {
    useEffect(() => {
        // updating every minute to load the new thresholds
        const interval = setInterval(() => {
            dispatch(loadSiloThresholds(plant));
        }, 60000);

        setExpiration(expirationTime);
        if (!siloFillings || !siloThresholds) {
            return;
        }
        let initial = siloFillings.map(s => {
            if (siloThresholds.map(a => a.content).includes(s.content)) {
                return siloThresholds.filter(a => a.content === s.content).map(a => { return { content: a.content, threshold: a.threshold, position: s.position } })[0];
            }
            return { content: s.content, threshold: 0, position: s.position };
        }).sort(sortByName);

        setThresholds(initial);
        setInitThresholds(initial);

        return () => {
            clearInterval(interval);
        }
    }, [expirationTime, siloFillings, siloThresholds, plant, dispatch]);

    const [expiration, setExpiration] = useState(0);
    const [thresholds, setThresholds] = useState([]);
    const [init, setInitThresholds] = useState([]);

    const valueChanged = (event) => {
        if (!isNumber(event.target.value)) {
            setExpiration(0);
            return;
        }
        let value = parseInt(event.target.value);
        if (value < 0 || value > 999)
            return;
        setExpiration(parseInt(value));
    }

    const isNumber = (val) => !isNaN(parseInt(val)) && isFinite(parseInt(val));

    const thresholdChanged = (event, val, position) => {
        let value;
        if (!isNumber(event.target.value))
            value = 0;
        else
            value = parseInt(event.target.value);
        if (value > 100 || value < 0)
            return;
        let newThresholds = [...thresholds.filter(s => s.content !== val), { content: val, threshold: value, position }];
        setThresholds(newThresholds);
    }

    const sortByName = (a, b) => {
        if (a.content < b.content)
            return -1;
        if (a.content > b.content)
            return 1;
        return 0;
    }

    const updateExpirationTime = () => {
        if (expirationTime !== expiration)
            dispatch(changeExpirationTime(plant, expiration));
        if (!(thresholds.every(val => init.every(e => e.threshold === val.threshold))))
            dispatch(changeSiloThresholds(plant, thresholds));
    }

    const hasSomethingChanged = expirationTime !== expiration || (thresholds.filter(e => init.filter(f => f.content === e.content && f.threshold !== e.threshold).length > 0).length > 0);

    return <div style={{ paddingBottom: 0, width: isMobileOnly ? "100%" : "500px" }}>
        <Card elevation={5}>
            <CardHeader title="Einstellungen" style={{ paddingBottom: 0 }} />
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                <List>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <TextField
                                label={"QR-Code Ablaufzeit"}
                                type={"text"}
                                fullWidth
                                value={expiration}
                                onChange={valueChanged}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Stunden</InputAdornment>,
                                    startAdornment: <InputAdornment position="start" style={{ visibility: "hidden" }}>%</InputAdornment>
                                }}
                            />
                        </ListItem>
                        <Tooltip title="Die Dauer wie lange ein QR-Code ab seiner Erstellung gültig ist">
                            <IconButton style={{ background: "none", paddingRight: 0 }} disableRipple>
                                <Info />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    {
                        thresholds && thresholds.sort((a, b) => b.position < a.position ? 1 : -1).map(silo => <div key={silo.content} style={{ display: "flex", flexDirection: "row" }}>
                            <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <TextField
                                    fullWidth
                                    label={"Schwellwert"}
                                    type={"text"}
                                    value={silo.threshold}
                                    inputProps={{ min: 0, max: 100 }}
                                    onChange={(event) => thresholdChanged(event, silo.content, silo.position)}
                                    InputProps={{
                                        endAdornment: < InputAdornment position="end" >{silo.content}</InputAdornment>,
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                />
                            </ListItem>
                            <Tooltip title="Ist der Füllstand des Silos größer als dieser Wert, können keine QR-Codes mehr durch Lieferanten erstellt werden">
                                <IconButton style={{ background: "none", paddingRight: 0 }} disableRipple>
                                    <Info />
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                    {hasSomethingChanged && <Button type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={updateExpirationTime}
                        style={{ marginTop: 10, marginBottom: 16, background: "#f48413", color: "#000" }}
                    >Speichern
                    </Button>}
                </List>
            </CardContent>
        </Card>
    </div >
};

const mapStateToProps = createStructuredSelector({
    expirationTime: state => state.plant.expirationTime,
    plant: state => state.application.currentPlant,
    siloFillings: state => state.plant.siloFillings,
    siloThresholds: state => state.plant.siloThresholds,
});

export default compose(connect(mapStateToProps))(SettingsList);