import React, { useState } from 'react';
import {
    AppBar as MuiAppBar,
    Typography,
    Toolbar,
    Button, IconButton,
    SwipeableDrawer,
    Drawer as MuiDrawer,
    List,
    Box,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
    ListItemAvatar
} from '@mui/material';
import { Refresh as RefreshIcon, Menu as MenuIcon, ChevronRight, ChevronLeft, ExpandMore as ExpandMoreIcon, ListAlt, ExpandLess } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { dispatchAwait, logOff, plantApplicationInit, selectPlant } from "../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { isMobileOnly } from 'react-device-detect';
import { DrawerItem } from '../utils/DrawerItem';

const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    ...({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // ...(open && {
        //     marginLeft: drawerWidth,
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     transition: theme.transitions.create(['width', 'margin'], {
        //         easing: theme.transitions.easing.sharp,
        //         duration: theme.transitions.duration.enteringScreen,
        //     }),
        // })
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        ...({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        })
    }),
);

const CustomAppBar = ({ dispatch, title, currentPlant, permissions, onRefresh, drawerActive, items, children, currentUser }) => {
    const plantList = permissions.map(p => p.plantName);
    const [open, setOpen] = useState(false);
    const [plantListOpen, setPlantListOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handlePlantListItemClick = (name) => {
        dispatchAwait(selectPlant(name), dispatch).then(() => {
            dispatch(plantApplicationInit());
        });
        setPlantListOpen(false);
        toggleMobileDrawerClick();
    }

    const toggleMobileDrawerClick = () => {
        if (isMobileOnly)
            setOpen(!open);
    }

    const togglePlantList = () => {
        if (!open)
            return;
        setPlantListOpen(!plantListOpen);
    }

    const drawerItems = () => {
        return [
            ...items.map((item) => <DrawerItem item={item} onClick={toggleMobileDrawerClick} />).map((e, i) => <div key={i}>{e} <Divider /> </div>),
            (plantList && plantList.length > 1) && <div key={"plantList"}>
                <ListItem button onClick={togglePlantList}>
                    <ListItemIcon style={{ color: "#000" }}><ListAlt /></ListItemIcon>
                    <ListItemText>Betonwerk</ListItemText>
                    {
                        open && <ListItemSecondaryAction>
                            {plantListOpen ? <ExpandLess /> : <ExpandMoreIcon />}
                        </ListItemSecondaryAction>
                    }
                </ListItem>
                <Collapse in={plantListOpen} timeout="auto" unmountOnExit>
                    {
                        plantList && plantList.map((plant, index) =>
                            <ListItem key={plant} button onClick={() => handlePlantListItemClick(plant)}>
                                <ListItemAvatar>
                                    <Typography>{index + 1}</Typography>
                                </ListItemAvatar>
                                <ListItemText style={{ color: "#000" }}>{plant}</ListItemText>
                            </ListItem>
                        )
                    }
                </Collapse>
                <Divider />
            </div>
        ];
    }

    return (<Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" color="primary" open={open}>
            <Toolbar>
                {
                    drawerActive && <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            marginRight: '36px',
                        }}
                        onClick={handleDrawerToggle}>
                        {
                            open ? <ChevronLeft /> : isMobileOnly ? <MenuIcon /> : <ChevronRight />
                        }
                    </IconButton>
                }
                <Typography variant="h6" noWrap component="div" style={{ flexGrow: 1 }}>
                    {title} {currentPlant && "- " + currentPlant}
                </Typography>
                <IconButton edge="end" aria-label="delete" style={{ marginRight: "16px" }} onClick={() => onRefresh()}>
                    <RefreshIcon style={{ color: "#000" }} />
                </IconButton>
                <Button color="inherit" onClick={() => dispatch(logOff())}>Logout</Button>
            </Toolbar>
        </AppBar>
        {
            isMobileOnly ?
                <div>
                    {
                        drawerActive && <SwipeableDrawer
                            anchor={"left"}
                            open={open}
                            width="100%"
                            onClose={handleDrawerClose}
                            onOpen={handleDrawerOpen}>
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeft />
                                </IconButton>
                            </DrawerHeader>
                            <List>
                                {
                                    drawerItems()
                                }
                            </List>
                        </SwipeableDrawer>
                    }
                </div> :
                <div>
                    {
                        drawerActive && <Drawer
                            anchor={"left"}
                            variant={"permanent"}
                            open={open}>
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeft />
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                            <List>
                                {
                                    drawerItems()
                                }
                            </List>
                        </Drawer>
                    }
                </div>
        }
        <Box component="main" sx={{ flexGrow: 1, p: isMobileOnly ? 1 : 2 }}>
            <DrawerHeader />
            {children}
        </Box>
    </Box >
    );
}

const mapStateToProps = createStructuredSelector({
    currentPlant: state => state.application.currentPlant,
    currentUser: state => state.application.currentUser,
    permissions: state => state.application.permissions
});

export default compose(connect(mapStateToProps))(CustomAppBar);