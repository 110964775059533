import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import fecha from "fecha";
import * as CryptoJS from 'crypto-js';
import "./../App.css";
import { DarkerDisabledTextField } from '../../utils/DarkerDisabledTextField';
import { isMobileOnly } from 'react-device-detect';

var QRCode = require('qrcode.react');

var keySize = 256;
var ivSize = 128;
var saltSize = 256;
var iterations = 1000;


const DownloadDialog = ({ open, content, expirationTime, driver, amount, numberPlate, supplier, handleClose, createdByPlant }) => {

    var expiration = new Date();
    var creation = new Date();
    expiration.setHours(expiration.getHours() + expirationTime);

    function encrypt(pass) {
        var msg = JSON.stringify({ content, expiration: expirationTime, creation: fecha.format(creation, "YYYY-MM-DDTHH:mm:ss"), driver, amount, numberPlate, supplier, createdByPlant });
        var salt = CryptoJS.lib.WordArray.random(saltSize / 8);
        var key = CryptoJS.PBKDF2(pass, salt, {
            keySize: keySize / 32,
            iterations: iterations
        });
        var iv = CryptoJS.lib.WordArray.random(ivSize / 8);
        var encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        var encryptedHex = base64ToHex(encrypted.toString());
        var base64result = hexToBase64(salt + iv + encryptedHex);
        return base64result;
    }

    function hexToBase64(str) {
        return btoa(String.fromCharCode.apply(null,
            str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
    }

    function base64ToHex(str) {
        for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
            var tmp = bin.charCodeAt(i).toString(16);
            if (tmp.length === 1) tmp = "0" + tmp;
            hex[hex.length] = tmp;
        }
        return hex.join("");
    }

    const handleClick = () => {
        const printableElements = document.getElementById("downloadDialog").innerHTML;
        var printerDiv = document.createElement("div");
        printerDiv.className = "printContainer";
        printerDiv.innerHTML = printableElements;
        window.print();
    }

    return <Dialog fullScreen={isMobileOnly} id={"downloadDialog"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">QR-Code für Lieferung</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {'Im Folgenden sind die von Ihnen angegebenen Informationen gezeigt. Bitte überprüfen Sie diese um einen reibungslosen Ablauf der Lieferung zu gewährleisten.'}
            </DialogContentText>
            <DarkerDisabledTextField
                margin="dense"
                id="download_supplier"
                label="Lieferant"
                fullWidth
                disabled
                value={supplier} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_supplierName"
                label="Fahrer"
                fullWidth
                disabled
                value={driver} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_numberPlate"
                label="Nummernschild"
                fullWidth
                disabled
                value={numberPlate} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_amount"
                label="Menge"
                fullWidth
                style={{ color: "#000" }}
                disabled
                value={amount} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_silo-number"
                label="Inhalt"
                fullWidth
                style={{ color: "#000" }}
                disabled
                value={content} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_creation"
                label="Erstelldatum"
                fullWidth
                style={{ textEmphasisColor: "#000" }}
                disabled
                value={fecha.format(creation, "DD.MM.YYYY HH:mm")} />
            <DarkerDisabledTextField
                margin="dense"
                id="download_expiration"
                label="Ablaufdatum"
                fullWidth
                disabled
                value={fecha.format(expiration, "DD.MM.YYYY HH:mm")} />
            <QRCode id={"qrCodeSVG"} value={open ? encrypt("wiggert_secret_aes") : ""} includeMargin={true} size={300} renderAs={"svg"}
                style={{ display: "block", margin: 'auto', }} level={"M"} />
        </DialogContent>
        <DialogActions id={"dialogActions"}>
            <Button id={"printBtn"} onClick={handleClick} color="primary">
                Drucken
            </Button>
            <Button id={"cancelBtn"} onClick={handleClose} color="primary">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
}

export default DownloadDialog;