import React from "react";
import UserEntry from "./UserEntry";
import { removeUserPermission } from "../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Card,
  CardContent,
  CardHeader,
  IconButton, ListItemSecondaryAction
} from '@mui/material'
import { CloudDoneRounded, CloudOffRounded, FileCopy } from "@mui/icons-material";

const OnlineIndicator = ({ online }) => {
  return (
    <ListItem style={{ paddingLeft: 0 }}>
      <ListItemIcon>
        {
          online ? <CloudDoneRounded style={{ color: "#1a1" }} /> : <CloudOffRounded style={{ color: "#a11" }} />
        }
      </ListItemIcon>
      <ListItemText primary={online ? "Verbunden" : "Nicht verbunden"} />
    </ListItem>
  );
}

const Plant = ({ plant, dispatch }) => {

  return <Card style={{ marginBottom: 16, maxWidth: 500, background: "#ddd" }} elevation={5}>
    <CardHeader title={plant.plantName} style={{ paddingBottom: 0 }} />
    <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
      <List>
        <OnlineIndicator online={plant.online} />
        {
          plant.initialPassword &&
          <ListItem style={{ paddingLeft: 0 }}>
            <ListItemText primary={plant.initialPassword} secondary="Passwort" />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => { navigator.clipboard.writeText(plant.initialPassword) }}>
                <FileCopy />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
        <ListSubheader style={{ fontWeight: "bold", color: "#000", paddingLeft: 0, backgroundColor:"#ddd" }}>Benutzer</ListSubheader>
        {
          plant.users.map((user, index) =>
            <UserEntry key={index} eMail={user.eMail} userRole={user.userRole} onRemove={() => dispatch(removeUserPermission(user.eMail, plant.plantName))}
              last={index === plant.users.length - 1} />
          )
        }
      </List>
    </CardContent>
  </Card>
};

export default compose(connect())(Plant);
