import React, { useState } from "react";
import { IconButton, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { Warning as WarningIcon, QrCodeScanner as QRCodeScannerIcon } from '@mui/icons-material';
import QRCodeDialog from "./dialogs/QRCodeDialog";

const Silo = ({ silo, threshold, userRole }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    return <ListItem>
        <ListItemText>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6">{silo.content}</Typography>
                        <Typography variant="h6">{silo.fillingAmount}%</Typography>
                    </div>
                    <div style={{ position: "relative", height: "6px", display: "block", backgroundColor: "#fad0a5" }}>
                        <div style={{ position: "absolute", left: 0, right: `${100 - silo.fillingAmount}%`, top: 0, bottom: 0, display: "block", backgroundColor: "#f48413" }} />
                        {
                            (typeof threshold != "undefined" && threshold !== 0) && <div style={{ position: "absolute", right: `${100 - threshold}%`, width: "3px", top: -4, bottom: -4, display: "flex", justifyContent: "center", backgroundColor: "#000" }}>
                                <h5 style={{ position: "absolute", top: -8 }}>{threshold}%</h5>
                            </div>
                        }
                    </div>
                </div>
                {
                    (userRole === "PlantAdmin" || threshold > silo.fillingAmount || threshold === 0) ?
                        <Tooltip title="QR-Code erstellen">
                            <IconButton style={{ margin: "0 auto", display: "table-row" }} aria-label="delete" onClick={handleClickOpen}>
                                <QRCodeScannerIcon fontSize="large" />
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title="Schwellwert erreicht. QR-Code kann nicht erstellt werden.">
                            <span>
                                <IconButton style={{ margin: "0 auto", display: "table-row" }} aria-label="delete" disabled>
                                    <WarningIcon fontSize="large" />
                                </IconButton>
                            </span>
                        </Tooltip>
                }
            </div>
        </ListItemText>
        {
            (userRole === "PlantAdmin" || threshold > silo.fillingAmount || threshold === 0) && <QRCodeDialog silo={silo} open={dialogOpen} handleClose={handleClose} />
        }
    </ListItem>
}

export default Silo;