import { completed, failed, LOAD_USERS, LOGOFF, pending, REGISTER_SUPPLIER, REGISTER_USER } from "../actions/names";

const DEFAULT_STATE = {
  users: [],
  error: null,
  pending: false,
};

const users = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOAD_USERS): {
      return {
        ...state,
        users: action.payload.users,
        pending: false,
      }
    }
    case pending(LOAD_USERS): {
      return {
        ...state,
        pending: true,
      }
    }
    case failed(LOAD_USERS): {
      return {
        ...state,
        error: action.error,
        pending: false,
      }
    }
    case completed(REGISTER_USER): {
      return {
        ...state,
        error: null,
      }
    }
    case failed(REGISTER_USER): {
      return {
        ...state,
        error: action.error,
      }
    }
    case completed(REGISTER_SUPPLIER): {
      return {
        ...state,
        error: null,
      }
    }
    case failed(REGISTER_SUPPLIER): {
      return {
        ...state,
        error: action.error,
      }
    }
    case LOGOFF: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
}
export default users;