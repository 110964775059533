import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import Silo from "./Silo";
import { List, ListItem, ListItemIcon, ListItemText, Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { isMobileOnly } from "react-device-detect";
import { ErrorText } from "./ErrorText";

const SiloFillings = ({ siloFillings, pending, error, siloThresholds, userRole, loadSiloFillings, loadThresholds }) => {
    useEffect(() => {
        // updating every minute to load the new silofillings
        const interval = setInterval(() => {
            if (loadSiloFillings) {
                loadSiloFillings();
            }
            if (loadThresholds) {
                loadThresholds();
            }
        }, 60000);

        return () => {
            clearInterval(interval);
        }
    }, [loadSiloFillings, loadThresholds]);

    return (
        <Card style={{ width: isMobileOnly ? "100%" : "500px" }} elevation={5}>
            <CardHeader title={"Silofüllstande"} style={{ paddingBottom: 10 }} />
            <CardContent>
                <List>
                    {
                        (!error && siloFillings) && <div>
                            {siloFillings.sort((a, b) => b.position < a.position ? 1 : -1).map((silo, index) => {
                                var s = siloThresholds.filter(a => a.content === silo.content)[0];
                                return <Silo key={index} silo={silo} threshold={!s ? s : s.threshold} userRole={userRole} />
                            })}
                        </div>
                    }
                    {
                        pending && <ListItem style={{ marginLeft: 0, width: "100%" }}>
                            <ListItemIcon style={{ marginRight: 5, marginLeft: 0 }}>
                                <CircularProgress />
                            </ListItemIcon>
                            <ListItemText primary={"Lade die Füllstände..."} />
                        </ListItem>
                    }
                    {
                        error && <ListItem>
                            <ListItemIcon style={{ marginRight: 5 }}>
                                <ErrorIcon style={{ color: "#ff4444" }} />
                            </ListItemIcon>
                            <ListItemText>
                                <ErrorText error={error} />
                            </ListItemText>
                        </ListItem>
                    }

                </List>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    siloFillings: state => state.plant.siloFillings,
    siloThresholds: state => state.plant.siloThresholds,
    expirationTime: state => state.plant.expirationTime,
    pending: state => state.plant.pendingSiloFillings,
    error: state => state.plant.errorSiloFillings,
    currentUser: state => state.application.currentUser,
    userRole: state => state.application.userRole,
});

export default compose(connect(mapStateToProps))(SiloFillings);
