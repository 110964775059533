import React from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';

export const LoadingDialog = ({ open, title, handleClose, error }) => {
    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                <CircularProgress />
            </div>
            <p style={{
                color: "#f44336",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center"
            }}>{error}</p>
        </DialogContent>
    </Dialog>
}