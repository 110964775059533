import { completed, failed, LOAD_LOG_ENTRIES, LOGOFF, pending, SELECTED_ENTRIES_END_TIME, SELECTED_ENTRIES_START_TIME, SELECTED_ENTRIES_SUPPLIER_NAME, } from "../actions/names";

const DEFAULT_STATE = {
    entries: [],
    totalEntries: 0,
    totalRequestEntries: 0,
    loadingEntries: false,
    errorEntries: null,
    selectedSupplierName: null,
    selectedStartTime: null,
    selectedEndTime: null,
};

const logs =  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case pending(LOAD_LOG_ENTRIES): {
            return {
                ...state,
                entries: [],
                loadingEntries: true,
                errorEntries: null,
            }
        }
        case failed(LOAD_LOG_ENTRIES): {
            return {
                ...state,
                errorEntries: action.error,
                loadingEntries: false
            }
        }
        case completed(LOAD_LOG_ENTRIES): {
            return {
                ...state,
                entries: action.payload.logEntries,
                totalEntries: action.payload.totalEntries,
                totalRequestEntries: action.payload.totalRequestEntries,
                loadingEntries: false,
                errorEntries: false
            }
        }

        case SELECTED_ENTRIES_END_TIME: {
            return {
                ...state,
                selectedEndTime: action.payload
            }
        }
        case SELECTED_ENTRIES_START_TIME: {
            return {
                ...state,
                selectedStartTime: action.payload
            }
        }
        case SELECTED_ENTRIES_SUPPLIER_NAME: {
            return {
                ...state,
                selectedSupplierName: action.payload
            }
        }
        case LOGOFF: {
            return DEFAULT_STATE;
        }
        default: {
            return state;
        }
    }
}
export default logs;