import React, { useEffect, useState } from 'react';
import './App.css';
import { applicationInit, passwordChanged } from "../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import UserList from "./UserList";
import PlantList from './PlantList';
import CustomAppBar from './CustomAppBar';
import { createStructuredSelector } from 'reselect';
import { SupervisorAccount, Home, List } from '@mui/icons-material';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';
import ChangePasswordDialog from './dialogs/ChangePasswordDialog';
import DeleteUserDialog from './dialogs/DeleteUserDialog';
const SiteAdminScreen = ({ dispatch, changePasswordError, changePasswordSuccess }) => {
  useEffect(() => {
    dispatch(applicationInit())
  }, [dispatch]);

  const [eMail, setEmail] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletedialogOpen, setDeleteDialogOpen] = useState(false);

  const onChangePassword = (eMail) => {
    setEmail(eMail);
    setDialogOpen(true);
  }

  const onRemoveUser = (eMail) => {
    setEmail(eMail);
    setDeleteDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
    dispatch(passwordChanged());
  }

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  }

  const navigationItems = [
    { "key": "home", "title": "Startseite", "icon": <Home /> },
    { "key": "plants", "title": "Betonwerke", "icon": <List /> },
    { "key": "users", "title": "Benutzer", "icon": <SupervisorAccount /> }
  ]

  return <Router>
    <div>
      <CustomAppBar dispatch={dispatch} title={"Wiggert Admin"} onRefresh={() => dispatch(applicationInit())} drawerActive={true} items={navigationItems} />
      <Switch>
        <Route exact path={["/home", "/"]}>
          <div style={{ display: "block", textAlign: "center" }}>
            <div style={{ display: "inline-block", verticalAlign: "top", textAlign: "left", margin: isMobileOnly ? "0 0 10px 0" : "10px 10px 0 10px" }}>
              <UserList registerUserHeader={"Neuen Benutzer hinzufügen"} onChangePassword={onChangePassword} onRemove={onRemoveUser} />
            </div>
            <div style={{ display: "inline-block", verticalAlign: "top", textAlign: "left", margin: isMobileOnly ? "0 0 10px 0" : "10px 10px 0 10px" }}>
              <PlantList />
            </div>
          </div>
        </Route>
        <Route path="/plants">
          <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center", margin: isMobileOnly ? "" : "10px 10px 0 10px" }}>
            <PlantList />
          </div>
        </Route>
        <Route path="/users">
          <div style={{ display: "flex", verticalAlign: "top", justifyContent: "center", margin: isMobileOnly ? "" : "10px 10px 0 10px" }}>
            <UserList registerUserHeader={"Neuen Benutzer hinzufügen"} onChangePassword={onChangePassword} />
          </div>
        </Route>
      </Switch>
      <ChangePasswordDialog dispatch={dispatch} eMail={eMail} open={dialogOpen} handleClose={handleClose}
        changePasswordError={changePasswordError} changePasswordSuccess={changePasswordSuccess} />
      <DeleteUserDialog dispatch={dispatch} eMail={eMail} open={deletedialogOpen} handleClose={handleDeleteClose} />
    </div>
  </Router>
};

const mapStateToProps = createStructuredSelector({
  changePasswordError: state => state.application.changePasswordError,
  changePasswordSuccess: state => state.application.changePasswordSuccess,
});

export default compose(connect(mapStateToProps))(SiteAdminScreen);