import React from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import { Card, CardContent, CardHeader, List, CircularProgress } from '@mui/material'
import RegisterSupplier from "./RegisterSupplier";
import PlantUserEntry from "./PlantUserEntry";
import { removeUserFromPlant } from "../../actions";
import { isMobileOnly } from "react-device-detect";
import { ErrorText } from "../ErrorText";

const PlantAdminUserList = ({ dispatch, users, pendingUsers, siloFillings, currentPlant, siloPermissions,
     addOrUpdateUserPermissionPending, addOrUpdateUserPermissionError }) => {

    const removeUser = (user) => {
        dispatch(removeUserFromPlant(currentPlant, user.email));
    }

    return <div style={{ width: isMobileOnly ? "100%" : "500px" }}>
        <Card elevation={5}>
            <CardHeader title="Benutzer" style={{ paddingBottom: 0 }} />
            <CardContent style={{ paddingTop: 0 }}>
                <List>
                    {
                        users.sort((a, b) => (a.email < b.email) ? 1 : -1).sort((a, b) => (a.userRole > b.userRole) ? 1 : -1)
                            .map((user, index) => <PlantUserEntry key={user.email + index} plant={currentPlant} user={user}
                                allSilos={user.userRole === "Supplier" && siloFillings} dispatch={dispatch}
                                blockedSilos={siloPermissions.filter(s => s.eMail !== user.email).flatMap(s => s.silos)}
                                onRemove={user.userRole === "Supplier" ? () => removeUser(user) : undefined} />)
                    }
                </List>
                {
                    pendingUsers && <CircularProgress />
                }
                <ErrorText error={addOrUpdateUserPermissionError} />
            </CardContent>
        </Card>
        <div style={{ marginTop: 10 }}>
            <RegisterSupplier />
        </div>
    </div>
};

const mapStateToProps = createStructuredSelector({
    users: state => state.plant.users,
    siloPermissions: state => state.plant.siloPermissions,
    currentPlant: state => state.application.currentPlant,
    pendingUsers: state => state.plant.pendingInfo,
    siloFillings: state => state.plant.siloFillings,
    addOrUpdateUserPermissionPending: state => state.plant.addOrUpdateUserPermissionPending,
    addOrUpdateUserPermissionError: state => state.plant.addOrUpdateUserPermissionError,
});

export default compose(connect(mapStateToProps))(PlantAdminUserList);