import React from 'react';
import './App.css';
import { createStructuredSelector } from "reselect";
import {connect} from "react-redux";
import {compose} from "redux";
import SiteAdminScreen from './SiteAdminScreen';
import PlantAdminScreen from './plantadmin/PlantAdminScreen';
import SupplierScreen from './supplier/SupplierScreen';

const App = ({userRole}) => {
  if (userRole === "SystemAdmin") {
    return <SiteAdminScreen/>
  } else if (userRole === "PlantAdmin") {
    return <PlantAdminScreen />
  } else if (userRole === "Supplier") {
    return <SupplierScreen />
  } else {
    throw Error("Unknown user role" + userRole);
  }
};
const mapStateToProps = createStructuredSelector({
  userRole: state => state.application.userRole,
});

export default compose(connect(mapStateToProps))(App);
