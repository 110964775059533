import { completed, failed, LOAD_SILO_FILLINGS, pending, LOGOFF, LOAD_PLANT_INFORMATION, LOAD_PLANT_EXPIRATION, LOAD_SILO_THRESHOLDS, ADD_USER_PERMISSION, REGISTER_PLANT, REGISTER_SUPPLIER, ADD_OR_UPDATE_USER_PERMISSION } from "../actions/names";

const DEFAULT_STATE = {
  siloFillings: [],
  users: [],
  siloPermissions: [],
  siloThresholds: [],
  expirationTime: 0,
  errorSiloFillings: null,
  errorInfo: null,
  pendingSiloFillings: false,
  pendingInfo: false,
  addedUserPermissionError: null,
  addedUserPermissionPending: false,
  registerSupplierError: null,
  registerSupplierPending: false,
  addOrUpdateUserPermissionError: null,
  addOrUpdateUserPermissionPending: false,
};

const plant = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOAD_SILO_FILLINGS): {
      return {
        ...state,
        siloFillings: action.payload.filter(e => e.content !== ""),
        pendingSiloFillings: false,
        errorSiloFillings: null,
      }
    }
    case pending(LOAD_SILO_FILLINGS): {
      return {
        ...state,
        errorSiloFillings: null,
        pendingSiloFillings: true,
      }
    }
    case failed(LOAD_SILO_FILLINGS): {
      return {
        ...state,
        errorSiloFillings: action.error,
        pendingSiloFillings: false,
        siloFillings: null,
      }
    }

    case completed(LOAD_PLANT_EXPIRATION): {
      return {
        ...state,
        expirationTime: action.payload.expirationTime,
      }
    }

    case completed(LOAD_PLANT_INFORMATION): {
      return {
        ...state,
        users: action.payload.users,
        expirationTime: action.payload.expirationTime,
        siloPermissions: action.payload.siloPermissions,
        siloThresholds: action.payload.thresolds,
        pendingInfo: false,
        addOrUpdateUserPermissionError: null,
        addedUserPermissionError: null,
        registerSupplierError: null,
      }
    }
    case completed(LOAD_SILO_THRESHOLDS): {
      return {
        ...state,
        siloThresholds: action.payload.thresholds,
      }
    }
    case pending(LOAD_PLANT_INFORMATION): {
      return {
        ...state,
        pendingInfo: true,
      }
    }
    case failed(LOAD_PLANT_INFORMATION): {
      return {
        ...state,
        errorInfo: action.error,
        pendingInfo: false,
      }
    }

    case completed(ADD_USER_PERMISSION): {
      return {
        ...state,
        addedUserPermissionError: null,
        addedUserPermissionPending: false,
      }
    }

    case pending(ADD_USER_PERMISSION): {
      return {
        ...state,
        addedUserPermissionPending: true,
      }
    }

    case failed(ADD_USER_PERMISSION): {
      return {
        ...state,
        addedUserPermissionError: action.error,
        addedUserPermissionPending: false
      }
    }

    case completed(REGISTER_SUPPLIER): {
      return {
        ...state,
        registerSupplierError: null,
        registerSupplierPending: false,
      }
    }

    case pending(REGISTER_SUPPLIER): {
      return {
        ...state,
        registerSupplierPending: true,
      }
    }

    case failed(REGISTER_SUPPLIER): {
      return {
        ...state,
        registerSupplierError: action.error,
        registerSupplierPending: false
      }
    }

    case failed(REGISTER_PLANT): {
      return {
        ...state,
        addedPlantError: action.error,
      }
    }

    case completed(REGISTER_PLANT): {
      return {
        ...state,
        addedPlantError: null,
      }
    }

    case completed(ADD_OR_UPDATE_USER_PERMISSION): {
      return {
        ...state,
        addOrUpdateUserPermissionError: null,
        addOrUpdateUserPermissionPending: false,
      }
    }

    case pending(ADD_OR_UPDATE_USER_PERMISSION): {
      return {
        ...state,
        addOrUpdateUserPermissionError: null,
        addOrUpdateUserPermissionPending: true,
      }
    }

    case failed(ADD_OR_UPDATE_USER_PERMISSION): {
      return {
        ...state,
        addOrUpdateUserPermissionError: action.error,
        addOrUpdateUserPermissionPending: false,
      }
    }

    case LOGOFF: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
}
export default plant;