import React, { useState } from "react";
import {
    List, ListItem, ListItemText, Dialog, DialogActions, DialogTitle,
    ListItemSecondaryAction, ListItemIcon, Checkbox, Button, Collapse, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { addOrUpdateUserPermission } from "../../actions";
import { RoleConversion } from "../../utils/Conversion";
import { isMobileOnly } from "react-device-detect";

const PlantUserEntry = ({ dispatch, onRemove, allSilos, user, plant, blockedSilos }) => {
    const [open, setOpen] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [silos, setSilos] = useState(user.silos);

    const updatePermission = () => {
        dispatch(addOrUpdateUserPermission(user.email, plant, [...silos.filter(e => e)]));
    }

    const handleSiloChange = (event) => {
        var silo = allSilos.map(s => s.content).filter(s => s === event.value);
        var newSilos;
        if (event.checked) {
            newSilos = [...silos.concat(silo)];
        } else {
            newSilos = [...silos.filter(silo => silo !== event.value)];
        }
        setSilos([...new Set(newSilos)]);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const hasSomeThingChanged = !((silos.length === user.silos.length) && (silos.every(val => user.silos.includes(val))));

    return (<div>
        <ListItem style={{ paddingLeft: 0 }} button={allSilos ? true : false} onClick={handleClick}>
            <ListItemText primary={user.email} secondary={RoleConversion(user.userRole)} />
            {
                allSilos && (open ? <ExpandLess /> : <ExpandMore />)
            }
            {
                onRemove && <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => setConfirmDialog(true)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            }
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            {
                allSilos && <List component="div" disablePadding>
                    {
                        allSilos.map((silo, index) => {
                            return <ListItem key={index} button onClick={() => handleSiloChange({ value: silo.content, checked: !silos.includes(silo.content) })}
                                disabled={blockedSilos.includes(silo.content)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        disabled={blockedSilos.includes(silo.content)}
                                        checked={silos.includes(silo.content)}
                                        name={silo.content}
                                        value={silo.content}
                                        onChange={(event) => handleSiloChange({ value: event.target.value, checked: event.target.checked })}
                                    />
                                </ListItemIcon>
                                <ListItemText id={index} primary={silo.content} />
                            </ListItem>
                        })
                    }
                </List>
            }
        </Collapse>
        {
            hasSomeThingChanged && <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={updatePermission}
                style={{ marginTop: 10, background: "#f48413", color: "#000" }}
            >Speichern
            </Button>
        }
        <Dialog fullScreen={isMobileOnly} id={"deleteDialog"} open={confirmDialog} onClose={() => setConfirmDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Möchten Sie den Benutzer "{user.email}" wirklich löschen?</DialogTitle>
            <DialogActions>
                <Button id={"deleteBtn"} onClick={() => { onRemove(); setConfirmDialog(false) }} color="primary">
                    Löschen
                </Button>
                <Button id={"cancelBtn"} onClick={() => setConfirmDialog(false)} color="primary">
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    </div >
    )
};

export default PlantUserEntry;