import React from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import Plant from "./Plant";
import RegisterPlant from "./RegisterPlant";
import { isMobileOnly } from "react-device-detect";

const PlantList = ({ plants }) => {
    return <div style={{ width: isMobileOnly ? "100%" : "500px" }}>
        {plants.map((plant, index) => {
            return <Plant key={index} plant={plant} />
        })}
        <RegisterPlant />
    </div>
};

const mapStateToProps = createStructuredSelector({
    plants: state => state.plantList.plants,
});

export default compose(connect(mapStateToProps))(PlantList);
