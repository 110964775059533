import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';

export const DrawerItem = ({ item, onClick }) => {
    const path = useLocation().pathname;
    let color = path === "/" + item.key || (path === "/" && item.key === "home") ? "#f48413" : "#000";
    return <Link to={item.key} key={item.key} onClick={onClick} style={{ color: color, textDecoration: "none" }}>
        <ListItem button>
            <ListItemIcon style={{ color: color }}>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
        </ListItem>
    </Link>
}