import { COMMIT_HASH, completed, failed, LOGIN, LOGOFF, SELECT_PLANT, pending, CHANGE_PASSWORD } from "../actions/names";

const DEFAULT_STATE = {
  loginToken: null,
  loginError: null,
  loginPending: false,
  changePasswordError: null,
  changePasswordSuccess: false,
  currentUser: null,
  userRole: null,
  permissions: [],
  currentPlant: null,
  commitHash: null
};

const application = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOGIN): {
      return {
        ...state,
        loginToken: action.payload,
        loginError: null,
        loginPending: false,
        userRole: action.role,
        permissions: action.permissions,
        currentPlant: action.plant,
        currentUser: action.currentUser,
      }
    }
    case completed(COMMIT_HASH): {
      return {
        ...state,
        commitHash: action.commitHash,
      }
    }
    case SELECT_PLANT: {
      return {
        ...state,
        currentPlant: action.payload,
      }
    }
    case failed(LOGIN): {
      return {
        ...state,
        loginError: action.error,
        loginPending: false,
      }
    }
    case pending(LOGIN): {
      return {
        ...state,
        loginPending: true,
        loginError: null,
      }
    }
    case failed(CHANGE_PASSWORD): {
      return {
        ...state,
        changePasswordError: action.error,
        changePasswordSuccess: false,
      }
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordError: null,
        changePasswordSuccess: false,
      }
    }
    case completed(CHANGE_PASSWORD): {
      return {
        ...state,
        changePasswordError: null,
        changePasswordSuccess: true,
      }
    }
    case LOGOFF: {
      return DEFAULT_STATE;
    }
    default: {
      return state;
    }
  }
}
export default application;